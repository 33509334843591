<template>
  <router-view />
</template>

<script>
export default {
  name: 'UserView',
  mounted () {
    console.log('User view');
  },
}
</script>

<style scoped>

</style>
